<template>
  <div>
    <Header />
    <section class="section1 bg-img-auto">
      <div class="text-box">
        <div class="h1">最佳营销短信平台</div>
        <div>通道质量高 | 短信到大快 | 价格巨优惠</div>
      </div>
    </section>

    <section class="section2">
      <div class="container container-space-y-m">
        <div class="h1 mb-5"
             align="center">我们的优势</div>
        <ul class="service">
          <li class="service-item">
            <div class="service-item-title">通道丰富</div>
            <p>三网合一，106通道丰富，VIP客户定制1对1独享通道</p>
          </li>
          <li class="service-item">
            <div class="service-item-title">响应快速</div>
            <p>系统智能拦截，免审核短信，网关秒提，独立端口专享通道</p>
          </li>
          <li class="service-item">
            <div class="service-item-title">系统防护</div>
            <p>数据加密，端口接入简单，服务器热备模式，故障自动无缝切换</p>
          </li>
          <li class="service-item">
            <div class="service-item-title">使用简单</div>
            <p>网页系统，免下载免安装，多功能系统管理，易上手</p>
          </li>
          <li class="service-item">
            <div class="service-item-title">安全保密</div>
            <p>一对一服务技术支持，合同协议保密性，法律保护共同利益</p>
          </li>
          <li class="service-item">
            <div class="service-item-title">高效服务</div>
            <p>7*24小时，1分钟内团队响应，强大技术保障运营支持，多对一模式，全公司都为您服务</p>
          </li>
        </ul>
      </div>
    </section>

    <!-- <BannerRegistration /> -->
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
export default {
  name: 'Home',
  components: {
    Header,
    Footer
  }
}

</script>

<style lang="scss" scoped>
.section1 {
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
    url("~@/assets/images/banner02.jpg");
  background-size: 100%;
  background-position: center;

  &::after {
    padding: 15%;
  }

  .text-box {
    position: absolute;
    color: #fff;
    left: 20%;
    top: 30%;
  }
}

.section2 {
  background: #f8f9fa;

  .service {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .service-item {
      width: 30%;
      height: 180px;
      padding: 30px;
      box-sizing: border-box;
      margin-bottom: 3%;
      border-radius: 14px;
      box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.1);
      background-clip: border-box;

      &:hover {
        box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.1);
      }
    }

    .service-item-title {
      font-size: 18px;
      text-align: center;
      margin-bottom: 12px;
    }
  }
}
</style>
